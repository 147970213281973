input {
  background: #FAFAFA;
border-radius: 100px; 
padding: 16px 20px;
font-size: 16px;
background: url("./search.svg") no-repeat scroll 16px 16px #FAFAFA;
background-size: 20px;
padding-left: 44px;
margin-left: -8px;
/* border: 1px solid #DDDDDD !important; */
border: none;
border-radius: 32px !important;
color: #222222 !important;
box-shadow: 40px 40px 40px rgba(204, 204, 204, 0.25), inset 2px 2px 2px #FFFFFF;
width: 50%;
line-height: 120%;
}

@media only screen and (max-width: 650px) {
  input {
    width: 80%;
  }
}

input:focus {
  /* box-shadow: 0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%) !important */
  box-shadow: 40px 40px 40px rgba(204, 204, 204, 0.25), inset 2px 2px 2px #FFFFFF;
}

form {
  margin: auto;
  position: relative;
  text-align: center;
}

body {
  padding: 80px;
  background: #F0F0F0;
}

@media only screen and (max-width: 650px) {
  body {
    padding: 40px;
    padding-top: 100px;
    background: #F0F0F0;
  }
}

input:focus {
  outline: none;
}

h1 {
  color: #222222;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

@media only screen and (max-width: 650px) {
  h1 {
    text-align: center;
  }
}

.search-page {
  padding-top: 40px;
  background-color: #F0F0F0;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.search-button {
  background: #000;
  color: #F8F9FA;
box-shadow: 40px 40px 40px rgb(204 204 204 / 25%);
border-radius: 20px; 
padding: 20px 40px;
margin-left: 16px;
border: none;
font-size: 16px;
text-decoration: none;
position: absolute; left: -9999px; width: 1px; height: 1px;
}

.rnftg-bg-gray-50 {
  background: none;
}

.rnftg-text-lg {
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgb(34, 34, 34) !important;
  padding-bottom: 16px;
  font-family: "Inter" sans-serif;
}


.rnftg-text-sm {
  font-size: 16px !important;
  line-height: 20px !important;
  color: rgb(113, 113, 113) !important;
  font-weight: 400;
}

.rnftg-border-gray-100 {
  display: none;
}

.rnftg-bg-white { 
background: #fff;
/* box-shadow: 40px 40px 40px rgb(204 204 204 / 25%), inset 2px 2px 2px #ffffff;
border-radius: 20px;  */
box-shadow: none;
border: 1px solid #f7f7f7;
background: #fafafa;
box-shadow: inset 2px 2px 2px #FFFFFF;
}

.rnftg-bg-white:hover {
  box-shadow: none;
  background: #F7F7F7;
}

.rnftg-p-6 {
  margin: -1.5em;
}

.home-image {
  /* background: url("https://i.imgur.com/tC46mVF.png"); */
  height: 200px;
  background-repeat: no-repeat;
  margin: auto;
  background-size: contain;
  background-position: center;
  border-radius: 4px;
  display: none;
}

.logo-image {
  width: 40px;
  left: 80px;
  position: absolute;
  top: 40px;
}

@media only screen and (max-width: 650px) {
  .logo-image{
    position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
  }
}

.perfundo__overlay {
  background: rgba(255, 255, 255, 0.2);
backdrop-filter: blur(100px);
}

.rnftg-py-2 {
  margin-top: 24px !important;
}

.rnftg-bg-transparent {
  background: #222;
  color: white;
  box-shadow: none;
}